.hosted-field {
    height: 40px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    width: 100%;
    background: white;
    margin-top: 4px;
}

.hosted-field.focused {
    border-color: #1890ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.hosted-field.invalid {
    border-color: #ff4d4f;
}

/* PayPal Button Responsive Styling */
.paypal-button-container {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}

/* Override PayPal iframe styles for mobile */
@media (max-width: 480px) {
    .paypal-buttons {
        width: 100% !important;
        min-width: 100% !important;
    }
}