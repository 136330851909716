* {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  /* background-color: #f4f4f4; */
}


.ant-menu-horizontal .ant-menu-item {
  background-color: white !important;
}

.ant-menu-horizontal .ant-menu-item:hover {
  border-bottom: none !important;
}

.ant-menu-title-content {
  background-color: white;
}



.ant-drawer-body {
  background-color: white !important;
}

@media (max-width: 768px) {
  .ant-menu-title-content {
    background-color: transparent !important;
    color: black;
  }
}


.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: inherit;
  color: black;
  font-size: large;
}


.custom-calendar .ant-picker-calendar-date {
  transition: all 0.3s ease;
  border-radius: 4px;
  margin: 2px;
  height: 100%;
}

.custom-calendar .ant-picker-calendar-date-today {
  border: 1px solid #1890ff;
}

.custom-calendar .ant-picker-calendar-date:hover {
  background-color: #f0f7ff;
}

.custom-calendar .ant-picker-cell-selected .ant-picker-calendar-date {
  background-color: #e6f7ff;
}

.custom-calendar .ant-picker-calendar-date-content {
  height: 100%;
  min-height: 60px;
}